.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #007bff;
}

.payment-container {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.back-button {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 20px;
  padding: 0;
}

.back-button:hover {
  color: #333;
}

.plan-summary {
  margin-bottom: 30px;
}

.plan-summary h2 {
  color: #333;
  margin-bottom: 20px;
}

.plan-details {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

.plan-details p {
  margin: 10px 0;
  color: #666;
}

.payment-form {
  margin-top: 30px;
}

/* Add spacing between credit card template and form */
.rccs {
  margin: 0 auto 40px;
}

.payment-form form {
  margin-top: 40px;
}

.payment-form h3 {
  color: #333;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-row {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}

.form-group.expiry,
.form-group.cvc {
  flex: 1;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .form-row {
    flex-direction: column;
    gap: 10px;
  }
  
  .payment-container {
    padding: 20px;
  }
}
