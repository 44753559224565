.pricing-wrapper {
  background: #3498db;
  min-height: 100vh;
  padding: 60px 20px;
}

.pricing-header {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 50px;
  position: relative;
}

.pricing-header h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 700;
}

.pricing-header p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
}

.billing-toggle {
  display: flex;
  justify-content: center;
  margin: 30px 0;
  gap: 15px;
  padding: 5px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: inline-flex;
}

.toggle-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #666;
}

.toggle-btn.active {
  background: #3498db;
  color: white;
  box-shadow: 0 2px 4px rgba(52, 152, 219, 0.3);
}

.pricing-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.item.pricing {
  background: white;
  border-radius: 20px;
  padding: 40px 30px;
  width: 320px;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.item.pricing:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.item.pricing .title {
  font-size: 1.5rem;
  color: #2c3e50;
  margin-bottom: 20px;
  font-weight: 700;
}

.item.pricing .item_price {
  font-size: 2.5rem;
  color: #3498db;
  font-weight: 700;
  margin-bottom: 30px;
}

.item.pricing .item_price span {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
}

.featureList {
  list-style: none;
  padding: 0;
  margin: 0 0 30px;
}

.featureList li {
  padding: 12px 0;
  color: #666;
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.check_list::before {
  content: "✓";
  color: #2ecc71;
  font-weight: bold;
  margin-right: 10px;
  font-size: 1.2rem;
}

.cross_list::before {
  content: "×";
  color: #e74c3c;
  font-weight: bold;
  margin-right: 10px;
  font-size: 1.2rem;
}

.buttonstyle {
  width: 100%;
  padding: 15px;
  border: none;
  border-radius: 10px;
  background: #3498db;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 20px;
}

.buttonstyle:hover {
  background: #2980b9;
}

.trial {
  text-align: center;
  color: #666;
  font-size: 1.1rem;
  margin: 0;
}

.recommended {
  position: absolute;
  top: -15px;
  right: 20px;
  background: #e74c3c;
  color: white;
  padding: 8px 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
}

.pricing-footer {
  text-align: center;
  margin-top: 50px;
  padding: 30px;
  background: white;
  border-radius: 15px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pricing-footer h2 {
  font-size: 1.5rem;
  color: #3498db;
  margin: 0;
  font-weight: 600;
}

@media (max-width: 768px) {
  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .item.pricing {
    width: 100%;
    max-width: 350px;
  }

  .pricing-header h2 {
    font-size: 2rem;
  }
}

.shape {
  position: absolute;
  top: -50px;
  right: -30px;
  width: 150px;
  opacity: 0.5;
  z-index: -1;
}

/* Add subtle animations */
@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.item.pricing {
  animation: float 6s ease-in-out infinite;
}

.item.pricing:nth-child(2) {
  animation-delay: 0.2s;
}

.item.pricing:nth-child(3) {
  animation-delay: 0.4s;
}

/* Rest of the original code remains the same */

p.description {
    font-size: 17px;
    color: #000000;
    padding: 0 14px;
    line-height: 20px;
    margin: 16px auto;
}
button.buttonstyle {
    font-size: 20px;
    margin-top: 41px;
    border-radius: 5px;
    /* border: 2px solid rgba(108, 36, 126, 0.2); */
    border: 3px solid #ccc;
    color: rgb(0, 117, 217);
    box-shadow: 2px 2px 10px 1px #ccc;
    letter-spacing: -0.5px;
    background-color: rgb(255, 255, 255);
    font-weight: 700;
    padding: 13px 30px;
}
.row.price {
    margin-top: 90px;
}
.item.pricing {
    border: 3px solid #ccc;
    margin-top: 30px;
    border-radius: 10px;
    box-sizing: 0px 0px 0px 32px #ccc;
    box-shadow: 3px 2px 14px 4px #ccc;
}
.pricig_main_section {
    background: rgb(0, 117, 217);
    position: relative;
}
.price_header, .price_inner {
    text-align: center;
    padding-top: 25px;
}
.price_header, .price_inner {
    text-align: center;
    padding-top: 25px;
}
.pricing-footer {
    background: rgb(0, 117, 217);
    padding: 40px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.item.pricing:nth-child(4) {
    box-shadow: 3px 2px 14px 4px #ccc;
}
.shape-one {
    right: 0px;
    top: 0px;
    position: absolute;
}
.pricing-footer h2 {
    line-height: 40px;
    font-size: 30px;
    color: #fff;
    text-align: center;
}

.Tabs .nav {
    display: flex;
    list-style-type: none;
    justify-content: center;
    background: rgb(0, 117, 217);
    margin: 0 auto;
    padding: 60px 0px 0px 0px;
}
ul.nav li.active {
    background: #fff;
    color: rgb(0, 117, 217);
    border: rgb(0, 117, 217);
}
ul.nav li:hover{
    cursor:pointer;
}
ul.nav li {
    background: #ccc;
    margin: 0px 10px;
    color: #202020;
    border-radius: 8px;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 40px;
}
@media only screen and (max-width: 768px) {
    ul.pricing_ul {

        padding: 0;
    }
    ul.pricing_ul li {
        font-size: 15px;
        margin-top: 0;
    }
    .item_price {
        font-size: 20px;
        line-height: 1;
    }
    button.buttonstyle {
        font-size: 16px;
        margin-top: 42px;
        padding: 13px 26px;
    }
    ul.featureList li {

        margin-top: 0px;
    }
    .nav-bar {
     
        flex-wrap: wrap;
   
}
ul.menu {
    
    padding: 0;
}
}
@media only screen and (max-width: 480px) {
div#root {
    width: 100%;
}
.pricing-footer h2 {
    max-width: 100%;

}
form {
    max-width: 100% !important;

}
input[type="text"] {
    display: block;
    max-width: 100%;
 
    width: 380px;
}
html {
    overflow-x: hidden;
}
body {
    overflow-x: hidden;
}
.price .wrap .item.pricing:nth-child(3) {
    margin-left: 0 !important;
}
.tabs-two {
    overflow-x: scroll !important;
}
.container {
    max-width: 100%;
    
}
.price .wrap {
    width: 902px;
    overflow-x: scroll;
}
.nav-bar {

    flex-wrap: wrap;
}
.footer-bar {
    width: 100%;
    text-align: center;
}
.footer-bottom-menu {
    width: 100%;
    text-align: center;
}
ul.icon {
    justify-content: center;
    padding: 0;
    margin: 0;
}
.footer-bottom-social-icons {
    width: 100%;
    text-align: center;
}
button.buttonstyle {

    padding: 13px 17px;
}
.footer-main {
    display: flex;
    margin: 0px 20px;
    flex-wrap: wrap;
}
.logo-section, .contact-info {
    width: 100% !important;
}
.Tabs .nav {
    flex-wrap: wrap;
    padding: 0;
}
.pricing-footer h2 {

    line-height: 30px;
    font-size: 23px;
}
.Tabs .nav li {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px !important;
}
.price_header, .price_inner {
    padding-right: 10px;
    padding-left: 10px;
}
}
@media only screen and (max-width: 390px) {

    input[type="text"] {
      
     
        width: 280px;
    }
}

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.payment-modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  position: relative;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.payment-modal h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.billing-toggle {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.toggle-btn {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.yearly-price {
  font-size: 1.2rem;
  color: #e74c3c;
  margin: 10px 0;
  text-align: center;
  font-weight: 600;
}
