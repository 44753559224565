/* Credit card form styles */

form {
  
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 30px 20px 20px;
    border: 2px solid #ccc;
    border-radius: 15px;
    box-shadow: 3px 4px 7px 1px #ccc;
}

  .logo {
    width: auto;
    max-height: 100%; 
  }
  .wrap p{
    color:rgb( 0, 117, 217);
  }
  .img {
    height:500px;
    width:500px;
    object-fit: none;
 }
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
  }
.aline{align-items: center;
  display: flex;
  gap: 20px;
}

input[type="text"] {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid rgb(0, 117, 217);
  box-shadow: 2px 1px 2px 0px #ccc;
  border-radius: 4px;
  max-width: 500px;
  font-size: 16px;
  margin-right: 30px !important;
  padding-right: 0px;
}
  input[type="cc"] {
    display: inline-block;
    width: calc(33.33% - 10px);
    padding: 10px;
    margin-bottom: 10px;
    border: 4px solid #ddd;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 16px;
  }

  input[type="text"]:focus{
    outline: none;
   
  }

  select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  .btn{
    button[type="submit"] {
      display: block;
      margin: 0 auto;
      padding: 10px 20px;
      background-color: #007bff;
      box-shadow: 3px 1px 4px 1px #ddd;
      border: none;
      border-radius: 4px;
      color: #fff;
      font-size: 17px;
      font-weight: 600;
      cursor: pointer;
  }
  
  }
  

  button[type="submit"] {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #007bff;
    box-shadow: 3px 1px 4px 1px #ddd;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    cursor: pointer;
  }

  button[type="submit"]:hover {
    background-color: #0056b3;
  }

  /* Credit card icon styles */

  .card-icon {
    display: inline-block;
    height: 40px;
    background-image: './png/credit-card.png';
    width: 40px;
    background-size: cover;
    margin-right: 10px;
  }

  /* Payment status styles */

  .payment-status {
    text-align: center;
    margin-top: 20px;
  }

  .payment-status.success {
    color: #28a745;
  }

  .payment-status.error {
    color: #dc3545;
  }
  .price .wrap {
    display: flex;
    vertical-align: middle;
    align-items: center;
    background: #fff;
    padding: 0px 35px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;}

 

  ul.pricing_ul li {
    font-size: 20px;
    padding: 0px 0px 16px 0px;
    border-bottom: 1px solid #ccc;
    margin: 15px 0px;
}

ul.pricing_ul {
    list-style-type: none;
}
h2.title {
  font-size: 18px;
  color: #959595;
}

.pricing {
  margin: 0px 10px;
  text-align:center;
}
.item {
  width: 25%;
}
.item_price span{
font-weight: 500;
font-size: 17px;
display: inline-block;
margin-left: 2px;
}
.item_price {
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}
ul.featureList {
  padding: 15px;
  list-style-type: none;
}
ul.featureList li {
  padding: 16px;
  text-align: center;
  margin-top: 5px;
}
button.buttonstyle {
  font-size: 20px;
  margin-top: 41px;
  border-radius: 5px;
  border: 2px solid rgba(108, 36, 126, 0.2);
  color: rgb(0, 117, 217);
  letter-spacing: -0.5px;
  background-color: rgb(255, 255, 255);
  font-weight: 700;
  padding: 13px 30px;
}
.trial {
  margin-top: 25px;
  font-size: 15px;
  color: rgba(15, 33, 55, 0.5);
  padding: 0px 15px;
  text-align: center;
}
button.buttonstyle:hover {
  color: rgb(255, 255, 255);
  border-color: rgb(0, 117, 217);
  background-color: rgb(0, 117, 217);
  cursor: pointer;
}
li.check_list:before {
  content: "\f058";
  display: block;
  color: green;
  font-family: fontawesome;
  font-size: 22px;
}
li.cross_list:before {
  content: "\f057";
  display: block;
  color: #c1c1c1;
  font-family: fontawesome;
  font-size: 22px;
}
.item.pricing {
  border: 1px solid #ccc;
  margin-top: 30px;
  border-radius: 10px;
}
.recommended {
  background-color: rgb(82, 172, 255);
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  min-height: 35px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 7px 7px 0px 0px;
}
.wrap.flex  {
  float: left;
  width: 33%;
  margin: 0px 6px 20px 0px;
}
.card-option {
  display: flex;
  gap: 14px;
} 
.message {
  color: #009100;
  padding: 0px 0px 15px 0px;
  font-size: 18px;
}
.error_message {
  color: red;
  padding: 0px 0px 15px 0px;
  font-size: 18px;
}

@media only screen and (max-width: 480px) {

.form-main .wrap-item {
  width: 100% !important;
}
.form-main {
  flex-wrap: wrap;
  padding: 60px 15px;
}
input[type="text"] {
  display: block;
  max-width: 100%;
  width: 100% !important;
}
.aline {
  flex-wrap: wrap;
  display: block;
}
.pricing-footer {
  padding-right: 20px !important;
  padding-left: 20px !important;
}
.form-main {
  flex-wrap: wrap;
  padding: 60px 15px !important;
}
}