.footer-section {
    background: white;
    padding: 40px 0 0 0;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.footer-main {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.logo-section, .contact-info {
    width: 50%;
}

.logo-section .img-header {
    height: 50px;
    width: auto;
}

.logo-section h2 {
    color: #3498db;
    margin: 20px 0;
    font-weight: 600;
}

.footer-img {
    margin: 10px 0;
}

.footer-img img {
    height: 40px;
    transition: transform 0.3s ease;
}

.footer-img img:hover {
    transform: scale(1.05);
}

.contact-info h3 {
    color: #3498db;
    margin-bottom: 20px;
    font-weight: 600;
}

.contact-info p {
    color: #666;
    margin: 10px 0;
    font-size: 1rem;
}

.contact p {
    margin: 6px 0;
}

p.address::before,
.contact::before,
.email::before {
    font-family: 'FontAwesome';
    margin-right: 10px;
    font-size: 20px;
    color: #3498db;
}

p.address::before {
    content: "\f041";
}

.contact::before {
    content: "\f095";
}

.email::before {
    content: "\f124";
}

.nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid rgba(52, 152, 219, 0.2);
    margin-top: 30px;
}

.footer-bar p {
    color: #666;
    margin: 0;
}

.footer-bottom-menu .menu {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.footer-bottom-menu .menu a {
    color: #3498db;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom-menu .menu a:hover {
    color: #2980b9;
}

.footer-bottom-menu .menu li {
    padding: 0 15px;
}

@media (max-width: 768px) {
    .footer-main {
        flex-direction: column;
        text-align: center;
    }

    .logo-section, .contact-info {
        width: 100%;
        margin-bottom: 30px;
    }

    .nav-bar {
        flex-direction: column;
        gap: 15px;
    }
}
