.processing-payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.processing-content,
.success-content,
.error-content {
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.success-icon,
.error-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.success-icon {
  color: #28a745;
}

.error-icon {
  color: #dc3545;
}

h2 {
  margin-bottom: 15px;
  color: #333;
}

p {
  color: #666;
  margin-bottom: 25px;
  line-height: 1.5;
}

.action-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .processing-content,
  .success-content,
  .error-content {
    padding: 30px;
  }
  
  h2 {
    font-size: 20px;
  }
  
  p {
    font-size: 14px;
  }
}
